import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";

export default function SalesRetailerDashboard() {

	const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
	const [faqsurl, setFaqsurl] = useState("");
	const [tncurl, setTncurl] = useState("");
  const [schemeurl, setSchemeurl] = useState("");
  const [rewardurl, setRewardurl] = useState("");

  useEffect(() => {
    getDashboardData();
		getFaqsData();
  }, []);

  const getDashboardData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/dashboard/Retailer`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program2,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setDashboardData(dt?.output);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setDashboardData({});
          }
        })
        .catch((err) => {});
    }
  };

	const getFaqsData = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(
          `${Constants.baseUrl}program/child/2`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              // appVersion: Constants.av,
              // platform: Constants.pt,
              // company: Constants.company,
              // program: Constants.program,
            },
          },
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setFaqsurl(dt?.output?.faqs);
						setTncurl(dt?.output?.tnc);
            setSchemeurl(dt?.output?.catelog);
            setRewardurl(dt?.output?.manual);
          }
          
        })
        .catch((err) => {});
    }
  };

  return (
    <>
      <Navbar />
      <section className="page-content--bge55" style={{ padding: "0px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 left-menu">
              <div className="item">
                <p className="top-title">Performance Summary - Retailers:</p>
                <ul>
                  <li>
                    Total Verified Retailers
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.members}</span>
                    </strong>
                  </li>
                  <li>
                    Total Secondary Reported{" "}
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.pendingverification}</span>
                    </strong>
                  </li>
                  <li>
                    Total Amount{" "}
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.pointsGiven}</span>
                    </strong>
                  </li>
                  <li>
                    {" "}
                    Redeemed Amount{" "}
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.pointsRedeemed}</span>
                    </strong>
                  </li>
                  <li>
                    {" "}
                    Balance Amount{" "}
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.balancePoints}</span>
                    </strong>
                  </li>
                </ul>
              </div>
              {/* <div className="item">
                <p className="top-title">Performance Summary - Plumbers:</p>
                <ul>
                  <li>
                    Total Verified Plumbers{" "}
                    <strong>
                      <span className="keep-right blue-color">0</span>
                    </strong>
                  </li>
                  <li>
                    Total Bar Codes Reported{" "}
                    <strong>
                      <span className="keep-right blue-color">0</span>
                    </strong>
                  </li>
                </ul>
              </div> */}
              <div className="item bottom">
                <p className="top-title">Verification Dependency:</p>
                <ul>
                  <li>
                    Pending Retailer Verification
                    <strong>
                      <span className="keep-right blue-color">{dashboardData?.pendingverification}</span>
                    </strong>
                  </li>
                  {/* <li>
                    Pending Plumber Verification{" "}
                    <strong>
                      <span className="keep-right blue-color">0</span>
                    </strong>
                  </li>
                  <li>
                    Pending Order Closures{" "}
                    <strong>
                      <span className="keep-right blue-color">0</span>
                    </strong>
                  </li> */}
                </ul>
                {/* <Link
                  to="/changepassword"
                  className="btn btn-primary"
                  type="button"
                >
                  Change Password
                </Link> */}
              </div>
            </div>
            <div className={`col-md-9`}>
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head1">Dashboard</h3>
                </div>
              </div>
              <div className="row">
                {localStorage.getItem("user_type") == "SALES" &&
                <>

                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/pending_verification">
                      <span>
                        <i className="fa fa-check-circle-o"></i>
                      </span>
                      <p>Pending for verification</p>
                    </Link>
                  </div>
                </div> */}
                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/verify_plumber">
                      <span>
                        <i className="fa fa-user"></i>
                      </span>
                      <p>Verify Plumber</p>
                    </Link>
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/distributor_reports">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Distributors Reports</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/retailer_report">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Retailer Reports</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to={rewardurl} target="_blank">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Reward Point Matrix</p>
                    </Link>
                  </div>
                </div>
                {/* <!-- <div className="col-md-3">
                                    <div className="dashboard-item" tabindex="0">
                                        <Link to="">
                                            <span>
                                                <i className="fa fa-file"></i>
                                            </span>
                                            <p>Plumber Reports</p>
                                        </Link>
                                    </div>
                                </div> --> */}

                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link
                      to={schemeurl} target="_blank"
                    >
                      <span>
                        <i className="fa fa-database"></i>
                      </span>
                      <p>Scheme & Promotions </p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/product_purchase_request">
                      <span>
                        <i className="fa fa-shopping-cart"></i>
                      </span>
                      <p>Product Purchase Requests</p>
                    </Link>
                  </div>
                </div>
								{/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/videos">
                      <span>
                        <i className="fa fa-video-camera"></i>
                      </span>
                      <p>Videos</p>
                    </Link>
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/sales/make_a_wish_history">
                      <span>
                        <i className="fa fa-magic"></i>
                      </span>
                      <p>Make a Wish History</p>
                    </Link>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/signage_board_status">
                      <span>
                        <i className="fa fa-map-signs"></i>
                      </span>
                      <p>Signage Board Status</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/FSNPSReport">
                      <span>
                        <i className="fa fa-pie-chart"></i>
                      </span>
                      <p>NPS</p>
                    </Link>
                  </div>
                </div> */}
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/FSReportData">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Other Reports</p>
                    </Link>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/RewardCalculator">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Reward Calculator</p>
                    </Link>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/plumber_reports">
                      <span>
                        <i className="fa fa-file"></i>
                      </span>
                      <p>Plumber Reports</p>
                    </Link>
                  </div>
                </div> */}

                {/* <div className="col-md-3">
                  <div className="dashboard-item" tabindex="0">
                    <Link to="/UploadShopImage">
                      <span>
                        <i className="fa fa-picture-o"></i>
                      </span>
                      <p>Display Incentive</p>
                    </Link>
                  </div>
                </div> */}
                </>
                }



                {localStorage.getItem("user_type") !== "SALES" &&
                  <>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/retailer_wise_redeemption">
                        <span>
                          <i class="fa fa-qrcode"></i>
                        </span>
                        <p>Retailer Wise Redeemption </p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/retailer_points">
                        <span>
                          <i class="fa fa-file"></i>
                        </span>
                        <p>Retailer Points</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/verify_retailer">
                        <span>
                          <i class="fa fa-file"></i>
                        </span>
                        <p>Verify Retailer</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/retailer_transaction">
                        <span>
                          <i class="fa fa-file"></i>
                        </span>
                        <p>Retailer Transaction</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/topten_retailer">
                        <span>
                          <i class="fa fa-file"></i>
                        </span>
                        <p>Top 10 Retailer</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dashboard-item" tabindex="0">
                      <Link to="/topten_retailer_branch_wise">
                        <span>
                          <i class="fa fa-file"></i>
                        </span>
                        <p>Top 10 Retailer Branch Wise</p>
                      </Link>
                    </div>
                  </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
