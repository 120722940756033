import React, { useState, useEffect } from "react";
import Navbar from "../../common/Navbar";
import login_left from "../../../assets/img/login-left.png";
import { Link } from "react-router-dom";
import axios from "axios";
import * as Constants from "../../../Constants/index";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [retailerUser, setRetailerUser] = useState(false);
  const [otpForm, setOtpForm] = useState(false);
  const [otpMobile, setOtpMobile] = useState(0);

  useEffect(() => {
    const userType = localStorage.getItem("user_type");
    const category = localStorage.getItem("category");
    if (userType === "SALES") {
      if (category === "influencer") {
        navigate("/influencer_dashboard");
      } else if (category === "retailer") {
        navigate("/sales_retailer_Dashboard");
      } else {
        //navigate("/sintex/category");
        navigate("/category2");
      }
    } else if (userType === "RETAILER") {
      navigate("/retailer_Dashboard");
    } else if (userType === "HO") {
      navigate("/influencer_dashboard");
    }
  }, []);
  const toggleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const loginFormik = useFormik({
    initialValues: {
      mobile: "",
      // password: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.mobile) {
        errors.mobile = "Mobile no. is required";
      } else if (!/^\d+$/.test(data.mobile)) {
        errors.mobile = "Mobile no. must be numeric";
      } else if (data.mobile.length !== 10) {
        errors.mobile = "Mobile no. must be 10 digits";
      }
      // if (!data.password) {
      //   errors.password = "Password is required";
      // }
      return errors;
    },
    onSubmit: (data) => {
      handleLoginMobile(data);
    },
  });
  const isLoginFormFieldValid = (name) =>
    !!(loginFormik.touched[name] && loginFormik.errors[name]);
  const getLoginFormErrorMessage = (name) => {
    return (
      isLoginFormFieldValid(name) && (
        <small className="p-error">{loginFormik.errors[name]}</small>
      )
    );
  };

  const handleCheckboxChange = (event) => {
    setRetailerUser(event.target.checked);
  };

  const handleLoginMobile = async (data) => {
    try {
      await axios
        .post(
          Constants.baseUrl +
            "auth/login-otp", //"auth/login-password"
          {
            mobile: data.mobile,
            // password: data.password,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: retailerUser ? Constants.program2 : Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          console.log(dt);
          if (dt.result === "success" && dt.code === 16001) {
            setOtpForm(true);
            setOtpMobile(data.mobile);
          } else {
            swal({
              text: dt.msg,
              icon: "error",
              buttons: false,
              timer: 2000,
            });
          }
          // if (dt.result === "success" && dt.code === 10001) {
          //   if (dt?.output?.user?.userType === "SALES") {
          //     localStorage.setItem("user_id", dt?.output?.user?.id);
          //     localStorage.setItem("user_type", dt?.output?.user?.userType);
          //     localStorage.setItem("user_name", dt?.output?.user?.name);
          //     localStorage.setItem("user_email", dt?.output?.user?.email);
          //     localStorage.setItem("user_mobile", dt?.output?.user?.mobile);
          //     localStorage.setItem("token", dt?.output?.access?.token);
          //     localStorage.setItem("userUniq_id", dt?.output?.user?.userUniqid);
          //     navigate("/sintex/category");
          //   } else if (dt?.output?.user?.userType === "RETAILER") {
          //     localStorage.setItem("user_id", dt?.output?.user?.id);
          //     localStorage.setItem("user_type", dt?.output?.user?.userType);
          //     localStorage.setItem("user_name", dt?.output?.user?.name);
          //     localStorage.setItem("user_email", dt?.output?.user?.email);
          //     localStorage.setItem("user_mobile", dt?.output?.user?.mobile);
          //     localStorage.setItem("token", dt?.output?.access?.token);
          //     localStorage.setItem("userUniq_id", dt?.output?.user?.userUniqid);
          //     localStorage.setItem("gst", dt?.output?.user?.gst);
          //     navigate("/retailer_Dashboard");
          //   } else if (dt?.output?.user?.userType === "HO") {
          //     localStorage.setItem("user_id", dt?.output?.user?.id);
          //     localStorage.setItem("user_type", dt?.output?.user?.userType);
          //     localStorage.setItem("user_name", dt?.output?.user?.name);
          //     localStorage.setItem("user_email", dt?.output?.user?.email);
          //     localStorage.setItem("user_mobile", dt?.output?.user?.mobile);
          //     localStorage.setItem("token", dt?.output?.access?.token);
          //     localStorage.setItem("userUniq_id", dt?.output?.user?.userUniqid);
          //     navigate("/influencer_dashboard");
          //   } else if (dt?.output?.user?.userType === "ADMIN") {
          //     swal({
          //       text: "Your mobile number is not registered",
          //       icon: "error",
          //       buttons: false,
          //       timer: 2000,
          //     });
          //   } else {
          //     swal({
          //       text: dt.msg,
          //       icon: "success",
          //       buttons: false,
          //       timer: 2000,
          //     });
          //   }
          // }
          // if (dt.result === "error" || dt.result === "dialog") {
          //   swal({
          //     text: dt.msg,
          //     icon: "error",
          //     buttons: false,
          //     timer: 2000,
          //   });
          // }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.otp) {
        errors.otp = "Otp is required";
      } else if (!/^\d+$/.test(data.otp)) {
        errors.otp = "Otp must be numeric";
      } else if (data.otp.length !== 6) {
        errors.otp = "Otp must be 6 digits";
      }

      if (!data.otp) {
        errors.otp = "Otp is required";
      }
      return errors;
    },
    onSubmit: (data) => {
      // console.log('login data', data)
      handleOtpMobile(data);
    },
  });
  const isOtpFormFieldValid = (name) =>
    !!(otpFormik.touched[name] && otpFormik.errors[name]);
  const getOtpFormErrorMessage = (name) => {
    return (
      isOtpFormFieldValid(name) && (
        <small className="p-error">{otpFormik.errors[name]}</small>
      )
    );
  };

  const handleOtpMobile = async (data) => {
    try {
      await axios
        .post(
          Constants.baseUrl +
            "auth/verify-otp",
          {
            mobile: otpMobile,
            otp: data.otp,
          },
          {
            headers: {
              "Accept-Language": localStorage.getItem("language"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: retailerUser ? Constants.program2 : Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success" && dt.code === 10001) {
            localStorage.setItem("user_id", dt?.output?.user?.id);
            localStorage.setItem("user_type", dt?.output?.user?.userType);
            localStorage.setItem("user_name", dt?.output?.user?.name);
            localStorage.setItem("user_email", dt?.output?.user?.email);
            localStorage.setItem("user_mobile", dt?.output?.user?.mobile);
            localStorage.setItem("token", dt?.output?.access?.token);
            localStorage.setItem("userUniq_id", dt?.output?.user?.userUniqid);

            if (dt?.output?.user?.userType === "SALES") {
              //navigate("/sintex/category");
              navigate("/category2");
            } else if (dt?.output?.user?.userType === "RETAILER") {
              navigate("/retailer_Dashboard");
            } else if (dt?.output?.user?.userType === "HO") {
              navigate("/category2");
            } else if (dt?.output?.user?.userType === "ZO") {
              navigate("/category2");
            } else if (dt?.output?.user?.userType === "RO") {
              navigate("/category2");
            } else if (dt?.output?.user?.userType === "SH") {
              navigate("/category2");
            } else if (dt?.output?.user?.userType === "ADMIN") {
              swal({
                text: "Your mobile number is not registered",
                icon: "error",
                buttons: false,
                timer: 2000,
              });
            } else {
              swal({
                text: dt.msg,
                icon: "success",
                buttons: false,
                timer: 2000,
              });
            }
          }
          if (dt.result === "error" || dt.result === "dialog") {
            swal({
              text: dt.msg,
              icon: "error",
              buttons: false,
              timer: 2000,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      loginFormik.handleSubmit();
    }
  };

  return (
    <>
      <Navbar />
      <section className="page-content--bge5">
        <div className="container">
          <div className="login-wrap">
            <div className="row bg-whitee">
              <div className="col-md-5 left-container">
                <img src={login_left} alt="" />
              </div>

              <div className="col-md-7">
                {otpForm ? (
                  <form onSubmit={otpFormik.handleSubmit}>
                    <div className="login-content">
                      <p>OTP sent on your registered mobile number</p>
                      <div className="form-group form-group-mb">
                        <label>Enter Otp</label>
                        <input
                          type="text"
                          id="otp"
                          value={otpFormik.values.otp}
                          maxLength="6"
                          onChange={otpFormik.handleChange}
                          className="form-control"
                          placeholder="Otp"
                          onKeyDown={handleKeyDown}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9]*$/;
                            if (!isNumeric.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {getOtpFormErrorMessage("otp")}
                      </div>
                      <button
                        className="btn btn-primary btn-block mt-20"
                        type="submit"
                      >
                        {" "}
                        Verify Otp
                      </button>
                    </div>
                  </form>
                ) : (
                  <form onSubmit={loginFormik.handleSubmit}>
                    <div className="login-content">
                      <p className="pp">
                        <strong>LOGIN TO YOUR SINTEX ACCOUNT</strong>
                      </p>
                      <p>Please use your credentials to login</p>
                      {/* <!-- <div className="alert alert-danger text-center"> Enter Valid Credentials </div> --> */}
                      <div className="form-group form-group-mb">
                        <label>Registered Mobile</label>
                        <input
                          type="text"
                          id="mobile"
                          value={loginFormik.values.mobile}
                          maxLength="10"
                          onChange={loginFormik.handleChange}
                          className="form-control"
                          placeholder="Mobile"
                          onKeyDown={handleKeyDown}
                          onKeyPress={(e) => {
                            const isNumeric = /^[0-9]*$/;
                            if (!isNumeric.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                        />
                        {getLoginFormErrorMessage("mobile")}
                      </div>
                      <div className="form-group form-group-mb">
                        <label>
                          <input
                            type="checkbox"
                            checked={retailerUser}
                            onChange={handleCheckboxChange}
                          />
                          Are you a retailer user?
                        </label>
                      </div>
                      {/* <div className="form-group form-group-mb">
                      <label>Password</label>{" "}
                      <span
                        className="showpass"
                        title="Show Password"
                        onClick={toggleShowPassword}
                      >
                        {showPassword === false ? (
                          <i className="fa fa-eye"></i>
                        ) : (
                          <i className="fa fa-eye-slash"></i>
                        )}
                      </span>
                      <input
                        type={showPassword === true ? "text" : "password"}
                        id="password"
                        value={loginFormik.values.password}
                        onChange={loginFormik.handleChange}
                        className="form-control"
                        placeholder="Password"
                        onKeyDown={handleKeyDown}
                      />
                      {getLoginFormErrorMessage("password")}
                    </div>
                    <div className="form-group text-right">
                      <Link
                        className="text-primary cursor-anchor"
                        to="/forgot_password"
                      >
                        Forgot Password?
                      </Link>
                    </div> */}
                      <button
                        className="btn btn-primary btn-block mt-20"
                        type="submit"
                      >
                        {" "}
                        Send Otp
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
