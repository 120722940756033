import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";

export default function ProductPurchaseRequest() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [productPurchase, setProductPurchase] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getProductPurchase(
      {
        search: "",
      },
      1
    );
  }, []);

  const searchFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setCurrentPage(1);
      getProductPurchase(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getProductPurchase = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `program/child/product/purchase/sales?sortBy=product&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `program/child/product/purchase/sales?name=${data.search}&sortBy=product&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          //console.log(res);
          const dt = res.data;
          if (dt.result === "success") {
            setProductPurchase(dt?.output?.results);
            setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setProductPurchase(null);
            setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const areAllValuesBlank = () => {
    const { startDate, endDate } = searchFormik.values;
    return !startDate && !endDate;
  };

  return (
    <>
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3 className="head2">Product Purchase Request</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <strong className="card-title">Purchase Requests</strong>
                </div>
                <div className="card-body">
                  <form onSubmit={searchFormik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-3 col-xs-12 text-right">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Retailer"
                            id="search"
                            name="search"
                            value={searchFormik.values.search}
                            onChange={(e) => {
                              searchFormik.handleChange({
                                target: {
                                  name: "search",
                                  value: e.target.value,
                                },
                              });
                            }}
                          />
                          {/* {getSearchFormErrorMessage("search")} */}
                        </div>
                      </div>
                      <div className="col-md-3 col-xs-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn btn-primary report-submit-button"
                            id="btnSearch"
                            // disabled={areAllValuesBlank()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive table--no-card">
                        <table className="table table-borderless table-striped table-earning">
                          <thead>
                            <tr>
                              <th> Request Date </th>
                              <th> Retailer Name </th>
                              <th> Retailer Contact No. </th>
                              <th> State </th>
                              <th> City </th>
                              <th> Associated Distributor </th>
                              <th> Product Name </th>
                              <th> Qty </th>
                              <th> Current Status </th>
                              {/* <th> Action </th> */}
                            </tr>
                          </thead>
                          {productPurchase === null ? (
                            <tbody>
                              <tr>
                                <td
                                  colSpan="9"
                                  style={{
                                    textAlign: "center",
                                    fontSize: "16px",
                                  }}
                                >
                                  No record found!
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {productPurchase &&
                                productPurchase.length > 0 &&
                                productPurchase.map((row, index) => {
                                  const serialNumber =
                                    (currentPage - 1) * limit + index + 1;

                                  const createdDate = row.createdDateTime
                                    ? moment(row.createdDateTime).format(
                                        "DD-MM-YYYY"
                                      )
                                    : "";
                                  return (
                                    <>
                                      <tr key={index}>
                                        {/* <td>{serialNumber}</td> */}
                                        <td>{createdDate}</td>
                                        <td>{row?.retailerName}</td>
                                        <td>{row?.mobile}</td>
                                        <td>{row?.workState}</td>
                                        <td>{row?.workCity}</td>
                                        <td>{row?.distributor}</td>
                                        <td>{row?.product}</td>
                                        <td>{row?.qnty}</td>
                                        <td>{row?.status}</td>
                                        {/* <td>
                                          <button
                                            type="button"
                                            className="btn btn-primary report-submit-button"
                                            id="btnSearch"
                                            // onClick={() => getRetailerData(row?.id)}
                                          >
                                            Open
                                          </button>

                                          <button
                                            type="button"
                                            className="btn btn-primary report-submit-button"
                                            id="btnSearch"
                                            // onClick={() => getRetailerData(row?.id)}
                                          >
                                            Close
                                          </button>
                                        </td> */}
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                        {totalPages > 1 && (
                          <ResponsivePagination
                            current={currentPage}
                            total={totalPages}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getProductPurchase(filterData, page);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
