import React, { useEffect, useState } from "react";
import * as Constants from "../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../common/Navbar";

export default function RetailerReport() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [verifyRetailer, setVerifyRetailer] = useState(null);
  const [verifyTotalPages, setVerifyTotalPages] = useState(0);
  const [verifyCurrentPage, setVerifyCurrentPage] = useState(1);
	const [verifyFilterData, setVerifyFilterData] = useState({});

	const [nonVerifyRetailer, setNonVerifyRetailer] = useState(null);
  const [nonVerifyTotalPages, setNonVerifyTotalPages] = useState(0);
  const [nonVerifyCurrentPage, setNonVerifyCurrentPage] = useState(1);
	const [nonVerifyFilterData, setNonVerifyFilterData] = useState({});

  const [limit] = useState(10);
  const [activeTab, setActiveTab] = useState("1a");

  useEffect(() => {
    getVerifyRetailer(
      {
        search: "",
      },
      1
    );

		getNonVerifyRetailer(
      {
        search: "",
      },
      1
    );
  }, []);

  const searchVerifiedFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setVerifyCurrentPage(1);
      getVerifyRetailer(data, 1);
      setVerifyFilterData(data);
    },
  });
  const isVerifiedSearchFormFieldValid = (name) =>
    !!(searchVerifiedFormik.touched[name] && searchVerifiedFormik.errors[name]);
  const getVerifiedSearchFormErrorMessage = (name) => {
    return (
      isVerifiedSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchVerifiedFormik.errors[name]}
        </small>
      )
    );
  };

  const getVerifyRetailer = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `user/manage?userType=RETAILER&status=APPROVED&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `user/manage?name=${data.search}&userType=RETAILER&status=APPROVED&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program2,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setVerifyRetailer(dt?.output?.results);
            setVerifyTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setVerifyRetailer(null);
            setVerifyTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };


	const searchNonVerifiedFormik = useFormik({
    initialValues: {
      search: "",
    },
    // validate: (data) => {
    //   let errors = {};
    //   if (data.search === "") {
    //     errors.search = "Please enter name";
    //   }
    //   return errors;
    // },
    onSubmit: (data) => {
      setNonVerifyCurrentPage(1);
      getNonVerifyRetailer(data, 1);
      setNonVerifyFilterData(data);
    },
  });
  const isNonVerifiedSearchFormFieldValid = (name) =>
    !!(searchVerifiedFormik.touched[name] && searchVerifiedFormik.errors[name]);
  const getNonVerifiedSearchFormErrorMessage = (name) => {
    return (
      isNonVerifiedSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchVerifiedFormik.errors[name]}
        </small>
      )
    );
  };

  const getNonVerifyRetailer = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      let url =
        Constants.baseUrl +
        `user/manage?userType=RETAILER&status=NEW&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;

      if (data.search !== "" && data?.search !== undefined) {
        url =
          Constants.baseUrl +
          `user/manage?name=${data.search}&userType=RETAILER&status=NEW&sortBy=name&sortAsc=true&limit=${limit}&page=${pageNum}`;
      }

      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program2,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setNonVerifyRetailer(dt?.output?.results);
            setNonVerifyTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setNonVerifyRetailer(null);
            setNonVerifyTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
			{loading ? <Oval /> : null}
      <Navbar />
      <section class="page-content--bge55">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="tabbable-panel margin-tops4 ">
                <div class="tabbable-line bggg33">
                  <ul class="nav nav-tabs tabtop  tabsetting">
                    <li className={activeTab === "1a" ? "active" : ""}>
                      {" "}
                      <Link
                        to=""
                        onClick={() => {
													setActiveTab("1a");
													getVerifyRetailer(
														{
															search: "",
														},
														1
													);
												}}
												
                        data-toggle="tab"
                        aria-expanded={activeTab === "1a"}
                      >
                        {" "}
                        Verified Retailers{" "}
                      </Link>{" "}
                    </li>
                    <li className={activeTab === "2a" ? "active" : ""}>
                      {" "}
                      <Link
                        to=""
												onClick={() => {
													setActiveTab("2a");
													getNonVerifyRetailer(
														{
															search: "",
														},
														1
													);
												}}
												
                        data-toggle="tab"
                        aria-expanded={activeTab === "2a"}
                      >
                        {" "}
                        Non-Verified Retailers
                      </Link>{" "}
                    </li>
                    <li className={activeTab === "3a" ? "active" : ""}>
                      {" "}
                      <Link
                        to=""
                        onClick={() => setActiveTab("3a")}
                        data-toggle="tab"
                        aria-expanded={activeTab === "3a"}
                      >
                        Retailer Accumulation Report{" "}
                      </Link>{" "}
                    </li>
                    {/* <!--  <li className={activeTab === '4a' ? 'active' : ''}> <Link to="#tab_default_4" onClick={() => setActiveTab('4a')} data-toggle="tab" aria-expanded={activeTab === '4a'}> Retailer Points Report </Link> </li> --> */}
                    <li className={activeTab === "5a" ? "active" : ""}>
                      {" "}
                      <Link
                        to=""
                        onClick={() => setActiveTab("5a")}
                        data-toggle="tab"
                        aria-expanded={activeTab === "5a"}
                      >
                        {" "}
                        Retailer Redmeption{" "}
                      </Link>{" "}
                    </li>
                    <li className={activeTab === "6a" ? "active" : ""}>
                      {" "}
                      <Link
                        to=""
                        onClick={() => setActiveTab("6a")}
                        data-toggle="tab"
                        aria-expanded={activeTab === "6a"}
                      >
                        {" "}
                        Retailer Sales History{" "}
                      </Link>{" "}
                    </li>
                  </ul>
                  <div class="card card-body mtop15">
                    <div class="tab-content margin-tops">
                      <div
                        className={`tab-pane ${
                          activeTab === "1a" ? "active" : ""
                        }`}
                        id="1a"
                      >
                        <form onSubmit={searchVerifiedFormik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-3 col-xs-12 text-right">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Search Retailer"
                                  id="search"
                                  name="search"
                                  value={searchVerifiedFormik.values.search}
                                  onChange={(e) => {
                                    searchVerifiedFormik.handleChange({
                                      target: {
                                        name: "search",
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                />
                                {/* {getSearchFormErrorMessage("search")} */}
                              </div>
                            </div>
                            <div className="col-md-3 col-xs-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn btn-primary report-submit-button"
                                  id="btnSearch"
                                  // disabled={areAllValuesBlank()}
                                >
                                  Search
                                </button>
                              </div>
                            </div>

                            <div className="col-md-3 col-md-offset-3 col-xs-12 text-right">
                              <div className="form-group">
                                <Link class="btn btn-primary" to="#">
                                  Export
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive table--no-card">
                              <table class="table table-borderless table-striped table-earning">
                                <thead>
                                  <tr>
                                    <th>Retailer Id</th>
                                    <th>Mobile No</th>
                                    <th>Name</th>
                                    <th>Firm Name</th>
                                    <th>State</th>
                                    <th>City</th>
                                    <th>Verification Date</th>
                                  </tr>
                                </thead>
                                {verifyRetailer === null ? (
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="7"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "16px",
                                        }}
                                      >
                                        No record found!
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    {verifyRetailer &&
                                      verifyRetailer.length > 0 &&
                                      verifyRetailer.map((row, index) => {
                                        const serialNumber =
                                          (verifyCurrentPage - 1) * limit + index + 1;

                                        // const invoiceDate = row?.invoiceDate
                                        //   ? moment(row?.invoiceDate).format(
                                        //       "DD-MM-YYYY"
                                        //     )
                                        //   : "";
                                        return (
                                          <>
                                            <tr key={index}>
                                              {/* <td>{serialNumber}</td> */}
                                              <td>{row?.merchantId}</td>
                                              <td>{row?.mobile}</td>
                                              <td>{row?.name}</td>
                                              <td>{row?.firmName}</td>
                                              <td>
                                                {row?.addressWork?.workState}
                                              </td>
                                              <td>
                                                {row?.addressWork?.workCity}
                                              </td>
                                              <td></td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </tbody>
                                )}
                              </table>
                              {verifyTotalPages > 1 && (
                                <ResponsivePagination
                                  current={verifyCurrentPage}
                                  total={verifyTotalPages}
                                  onPageChange={(page) => {
                                    setVerifyCurrentPage(page);
                                    getVerifyRetailer(verifyFilterData, page);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div class="row mtop15">
                          <div class="col-md-2 col-xs-6 text-left">
                            <div class="form-group">
                              <Link class="btn btn-primary" to="#">
                                Previous
                              </Link>
                            </div>
                          </div>
                          <div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
                            <div class="form-group">
                              <Link class="btn btn-primary" to="#">
                                Next
                              </Link>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div
                        className={`tab-pane ${
                          activeTab === "2a" ? "active" : ""
                        }`}
                        id="2a"
                      >
                        <form onSubmit={searchNonVerifiedFormik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-3 col-xs-12 text-right">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Search Retailer"
                                  id="search"
                                  name="search"
                                  value={searchNonVerifiedFormik.values.search}
                                  onChange={(e) => {
                                    searchNonVerifiedFormik.handleChange({
                                      target: {
                                        name: "search",
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                />
                                {/* {getSearchFormErrorMessage("search")} */}
                              </div>
                            </div>
                            <div className="col-md-3 col-xs-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn btn-primary report-submit-button"
                                  id="btnSearch"
                                  // disabled={areAllValuesBlank()}
                                >
                                  Search
                                </button>
                              </div>
                            </div>

                            <div className="col-md-3 col-md-offset-3 col-xs-12 text-right">
                              <div className="form-group">
                                <Link class="btn btn-primary" to="#">
                                  Export
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive table--no-card">
                              <table class="table table-borderless table-striped table-earning">
                                <thead>
                                  <tr>
                                    <th>Retailer Id</th>
                                    <th>Mobile No</th>
                                    <th>Name</th>
                                    <th>Firm Name</th>
                                    <th>State</th>
                                    <th>City</th>
                                  </tr>
                                </thead>
                                {nonVerifyRetailer === null ? (
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="7"
                                        style={{
                                          textAlign: "center",
                                          fontSize: "16px",
                                        }}
                                      >
                                        No record found!
                                      </td>
                                    </tr>
                                  </tbody>
                                ) : (
                                  <tbody>
                                    {nonVerifyRetailer &&
                                      nonVerifyRetailer.length > 0 &&
                                      nonVerifyRetailer.map((row, index) => {
                                        const serialNumber =
                                          (nonVerifyCurrentPage - 1) * limit + index + 1;

                                        // const invoiceDate = row?.invoiceDate
                                        //   ? moment(row?.invoiceDate).format(
                                        //       "DD-MM-YYYY"
                                        //     )
                                        //   : "";
                                        return (
                                          <>
                                            <tr key={index}>
                                              {/* <td>{serialNumber}</td> */}
                                              <td>{row?.merchantId}</td>
                                              <td>{row?.mobile}</td>
                                              <td>{row?.name}</td>
                                              <td>{row?.firmName}</td>
                                              <td>
                                                {row?.addressWork?.workState}
                                              </td>
                                              <td>
                                                {row?.addressWork?.workCity}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                  </tbody>
                                )}
                              </table>
                              {nonVerifyTotalPages > 1 && (
                                <ResponsivePagination
                                  current={nonVerifyCurrentPage}
                                  total={nonVerifyTotalPages}
                                  onPageChange={(page) => {
                                    setNonVerifyCurrentPage(page);
                                    getNonVerifyRetailer(nonVerifyFilterData, page);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeTab === "3a" ? "active" : ""
                        }`}
                        id="3a"
                      >
                        <div class="row" style={{ paddingBottom: "20px" }}>
                          <div class="col-md-2">
                            <div class="form-group">
                              <input
                                class="form-control reset"
                                type="text"
                                placeholder="Search Retailer"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <select class="form-control reset">
                                <option value="-1">Select Month</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <select class="form-control reset">
                                <option value="-1">Select Year</option>
                                <option value="2023">2023</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <button
                                type="button"
                                class="btn btn-primary btn-sbmit"
                              >
                                Search
                              </button>
                            </div>
                          </div>
                          <div class="col-md-4 text-right">
                            <div class="form-group">
                              <Link
                                class="btn btn-primary"
                                to="javascript:void(0)"
                                id=""
                              >
                                Export
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive table--no-card">
                              <table class="table table-borderless table-striped table-earning">
                                <thead>
                                  <tr>
                                    <th>Retailer Code</th>
                                    <th>Distributor Name</th>
                                    <th>Firm Name</th>
                                    <th>
                                      Opening 'Locked' Rewards on 30-Nov-22
                                    </th>
                                    <th>
                                      Earned 'Unlocked' Rewards w.e.f. 01-Dec-22
                                    </th>
                                    <th>TDS w.e.f. 01-Dec-22</th>
                                    <th>Redeemed w.e.f. 01-Dec-22</th>
                                    <th>
                                      Total 'Locked + Unlocked' Rewards Balance
                                    </th>
                                    <th>Redeemable in current month</th>
                                    <th>Group 1 (Total Litterage)</th>
                                    <th>Group 2 (Total Litterage)</th>
                                    <th>Product Purchase Count</th>
                                    <th>
                                      Rewards Earned in Current Month (Including
                                      TDS)
                                    </th>
                                    <th>Month</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className={`tab-pane ${activeTab === '4a' ? 'active' : ''}`} id="4a">
												<div class="row">
													<div class="col-md-3 col-xs-6 text-right">
														<div class="form-group">
															<input class="form-control" type="text" placeholder="Search Retailer" />
														</div>
													</div>
													<div class="col-md-3 col-md-offset-6 col-xs-6 text-right">
														<div class="form-group">
															<Link class="btn btn-primary" to="#">
																Export
															</Link>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="table-responsive table--no-card">
															<table class="table table-borderless table-striped table-earning">
																<thead>
																	<tr>
																		<th>Retailer Id</th>
																		<th>Name</th>
																		<th>Distributor Name</th>
																		<th>Firm Name</th>
																		<th>Secondary Points</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>11</td>
																		<td></td>
																		<td></td>
																		<td></td>
																		<td></td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<div class="row mtop15">
													<div class="col-md-2 col-xs-6 text-left">
														<div class="form-group">
															<Link class="btn btn-primary" to="#">
																Previous
															</Link>
														</div>
													</div>
													<div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
														<div class="form-group">
															<Link class="btn btn-primary" to="#">
																Next
															</Link>
														</div>
													</div>
												</div>
											</div> */}
                      <div
                        className={`tab-pane ${
                          activeTab === "5a" ? "active" : ""
                        }`}
                        id="5a"
                      >
                        <div class="row">
                          <div class="col-md-3 col-xs-6 text-right">
                            <div class="form-group">
                              <input
                                class="form-control"
                                type="text"
                                placeholder="Search Retailer"
                              />
                            </div>
                          </div>
                          <div class="col-md-3 col-md-offset-6 col-xs-6 text-right">
                            <div class="form-group">
                              <Link class="btn btn-primary" to="#">
                                Export
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive table--no-card">
                              <table class="table table-borderless table-striped table-earning">
                                <thead>
                                  <tr role="row">
                                    <th>DMS Id</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Firm Name</th>
                                    <th>Mobile No</th>
                                    <th>Order Date</th>
                                    <th>Voucher Code</th>
                                    <th>Points Redeemed</th>
                                    <th>Status</th>
                                    <th>Zone</th>
                                    <th>Branch</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>11</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div class="row mtop15">
                          <div class="col-md-2 col-xs-6 text-left">
                            <div class="form-group">
                              <Link class="btn btn-primary" to="#">
                                Previous
                              </Link>
                            </div>
                          </div>

                          <div class="col-md-2 col-md-offset-8 col-xs-6 text-right">
                            <div class="form-group">
                              <Link class="btn btn-primary" to="#">
                                Next
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane ${
                          activeTab === "6a" ? "active" : ""
                        }`}
                        id="6a"
                      >
                        <div class="row" style={{ paddingBottom: "20px" }}>
                          <div class="col-md-2">
                            <div class="form-group">
                              <input
                                class="form-control reset"
                                type="text"
                                placeholder="Search Retailer"
                              />
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <select class="form-control reset">
                                <option value="-1">Select Month</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <select class="form-control reset">
                                <option value="-1">Select Year</option>
                                <option value="2023">2023</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="form-group">
                              <button
                                type="button"
                                class="btn btn-primary btn-sbmit"
                              >
                                Search
                              </button>
                            </div>
                          </div>

                          <div class="col-md-4 text-right">
                            <div class="form-group">
                              <Link
                                class="btn btn-primary"
                                to="javascript:void(0)"
                                id=""
                              >
                                Export
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="table-responsive table--no-card">
                              <table class="table table-borderless table-striped table-earning">
                                <thead>
                                  <tr role="row">
                                    <th>Retailer Code</th>
                                    <th>Distributor Name</th>
                                    <th>Firm Name</th>
                                    <th>Group 1 (Total Litterage)</th>
                                    <th>Group 2 (Total Litterage)</th>
                                    <th>Product Purchase Count</th>
                                    <th>
                                      Rewards Earned in Current Month (Including
                                      TDS)
                                    </th>
                                    <th>Month</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
