import React, { useEffect, useState } from "react";
import * as Constants from "../../../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useFormik } from "formik";
import ResponsivePagination from "react-responsive-pagination";
import Oval from "../../loader/CircleLoade";
import { Link } from "react-router-dom";
import Navbar from "../../../common/Navbar";

export default function RewardPoints() {
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [monthYears, setMonthYears] = useState([]);
  const [pointsData, setPointsData] = useState([]);
  const [monthName, setMonthName] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(10);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    getMonthYear();
  }, []);

  const getMonthYear = async () => {
    if (!token) {
      navigate("/");
    } else {
      await axios
        .get(`${Constants.baseUrl}purchase/retailer/month_year`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
            appVersion: Constants.av,
            platform: Constants.pt,
            company: Constants.company,
            program: Constants.program,
          },
        })
        .then((res) => {
          const dt = res.data;
          if (dt.result === "success") {
            setMonthYears(dt?.output);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setMonthYears([]);
          }
        })
        .catch((err) => {});
    }
  };

  const searchFormik = useFormik({
    initialValues: {
      month_year: "",
    },
    validate: (data) => {
      let errors = {};
      if (data.month_year === "") {
        errors.month_year = "Please select month year";
				setPointsData([]);
      }
      return errors;
    },
    onSubmit: (data) => {
      setCurrentPage(1);
      getRewardPointData(data, 1);
      setFilterData(data);
    },
  });
  const isSearchFormFieldValid = (name) =>
    !!(searchFormik.touched[name] && searchFormik.errors[name]);
  const getSearchFormErrorMessage = (name) => {
    return (
      isSearchFormFieldValid(name) && (
        <small className="report-error-field">
          {searchFormik.errors[name]}
        </small>
      )
    );
  };

  const getRewardPointData = async (data, pageNum) => {
    setLoading(true);
    if (!token) {
      navigate("/");
    } else {
      const searchMonthYear =
        data?.month_year !== undefined && data?.month_year !== ""
          ? `&monthYear=${data?.month_year}`
          : "";

      const month = data?.month_year.split(" ")[0];
      setMonthName(month);

      await axios
        .get(
          Constants.baseUrl +
            `purchase/month_year/purchase_History?${searchMonthYear}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${pageNum}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          const dt = res.data;

          if (dt.result === "success") {
            setPointsData(dt?.output);
            // setTotalPages(dt?.output?.totalPages);
            setLoading(false);
          }
          if (dt.result === "error" || dt.result === "dialog") {
            setPointsData([]);
            // setTotalPages(0);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };


  const areAllValuesBlank = () => {
    const { month_year } = searchFormik.values;
    return !month_year;
  };
  return (
    <>
      {loading ? <Oval /> : null}
      <Navbar />
      <section className="page-content--bge55">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="head2">Reward Points</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h1 class="text-center">Coming Soon</h1>
                </div>
              </div>

              {/* <form onSubmit={searchFormik.handleSubmit}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <select
                        className="form-control reset"
                        id="month_year"
                        name="month_year"
                        value={searchFormik.values.month_year}
                        onChange={searchFormik.handleChange}
                      >
                        <option value="">Select Month Year</option>
                        {monthYears.length > 0 &&
                          monthYears.map((monthYear, index) => (
                            <option key={index} value={monthYear}>
                              {monthYear}
                            </option>
                          ))}
                      </select>
                      {getSearchFormErrorMessage("month_year")}
                    </div>
                  </div>
                  
                  <div className="col-md-3">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary report-submit-button"
                        id="btnSearch"
                        disabled={areAllValuesBlank()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive table--no-card">
                    <table className="table table-borderless table-striped table-earning">
                      <thead>
                        <tr role="row">
                          <th>Sr No</th>
                          <th>Group 1 (Total Litterage)</th>
                          <th>Group 2 (Total Litterage) </th>
                          <th>Product Purchase Count </th>
                          <th>
                            Rewards Earned In Current Month (Including TDS)
                          </th>
                          <th>Month</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(pointsData).length > 0 && (
                          <tr>
                            <td>1</td>
                            <td>{pointsData.group1Ltr}</td>
                            <td>{pointsData.group2ltr}</td>
                            <td>{pointsData.noOfProduct}</td>
                            <td>{pointsData.totalPoint}</td>
                            <td>{monthName}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
